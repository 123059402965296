<template lang="pug">
	.audit(v-if="ready")
		p {{ get_username }} {{ get_useremail }}
		.workbook(v-if="loaded")
				p Workbook:
						span(v-if="get_auditForm.workbook") {{get_auditForm.workbook}}
						span(v-else) Not started, yet.
		bunt-select#select-audit(name="audit-select" label="select audit" v-model="selectForm" :options="this.get_auditList" option-label="projectname" @input="changeForm")
			template(slot-scope="{ option }")
				div
					p Auditid: {{ option.auditid ? option.auditid : '0' }}
					div.divider
					p Projectname: {{ option.projectname ? option.projectname : 'NoName' }}
					div.divider
					p Branche: {{ option.branch ? option.branch : 'No Branche'}}
					div.divider
					p Usecase: {{ option.case ? option.case : 'No Case' }}
					div.divider
					p Type: {{ option.type ? option.type : 'No Type' }}
		auditForm()
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import auditForm from 'components/audit/auditform'

export default {
	props: ['id'],
	components: {
		auditForm
	},
	data () {
		return {
			user: null,
		}
	},
	computed: {
		...mapState(['users', 'audit']),
		...mapGetters('audit', ['ready', 'loaded', 'get_username', 'get_useremail', 'get_auditList', 'get_auditForm', 'get_nextAuditId']),
		queryEmail () { return this.$route.query.email },
		queryID () { return parseInt(this.$route.params.id, 10) },
		propID () { return parseInt(this.id, 10) },
		useremail () { return this.get_useremail },
		selectForm: {
			get () { return this.$store.state.audit.selectedAudit },
			set (val) { return this.$store.commit('audit/SET_SELECTEDAUDIT', val) }
		}
	},
	created () {
		this.$store.commit('audit/SET_SELECTEDAUDIT', null)
		this.user = null
		this.setUser()
	},
	mounted () {
	},
	methods: {
		setUser () {
			const user = this.users.find((user) => {
				return user.id === this.propID ||
				user.id === this.queryID ||
				user.email === this.queryEmail
					? user
					: null
			})
			this.user = user
			this.$store.dispatch('audit/set_user', user)
		},
		changeForm () {
			this.$store.dispatch('audit/set_auditform', this.$store.state.audit.selectedAudit)
		}
	},
}
</script>
<style lang="stylus" scoped>
@import '~_settings'

.audit
	display: flex
	flex-direction: column
	align-items: left
	height: 100%
	width: 100%
	overflow-y: scroll
	> *
		margin-left: 2%
		margin-right: 5%
#select-audit
	select-style
#select-audit
	ul li
		div
			display: flex
			flex-direction: row
			justify-content: space-between
			align-content: center
			> *
				margin: 10px 0
			.divider
				width: 1px;
				margin: 10px 0
				background: black
			p
				font-weight: 400
		.id
			width: 120px
			text-align: right
			padding: 0 8px
			color: $clr-secondary-text-dark

</style>

import { required } from 'vuelidate/lib/validators'

const churn = {
	churn_reason: {
		required
	},
	audit_cause: {
		required
	},
	projectname: {
		required
	},
	branch: {
		required
	},
	branchtype: {
		required
	},
	branchcase: {
		required
	},
	agent: {
		required
	},
	sr: {
		required
	},
	summary: {
		required
	},
	recommendation: {
		required
	}
}

export default churn

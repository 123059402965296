import Router from 'vue-router'

import App from './App'
import Login from 'views/login'
import Users from 'views/users'
import User from 'views/user'
import Audit from 'views/audit'

// MAGIC ROUTE NAMING SYNTAX
// =========================
// route names get used to determine the active navigation state (selected tab)
// To allow for subviews to leave the navigation active, use following naming scheme:
// `name-of-the-navigation:any-other-name-you-want`
// Navigation will cut off the name at the `:` and only use the first part to determine state.

const routes = [{
	path: '/',
	component: App,
	meta: {requiresAuth: true},
	children: [{
		path: '/',
		name: 'users',
		component: Users,
		children: [{
			path: 'user/:id',
			name: 'user',
			component: User
		},
		{
			path: 'audit/:id',
			name: 'audit',
			component: Audit,
			props: true
		}]
	}]
}, {
	path: '/login',
	component: Login
}]
const router = Object.create(Router.prototype)
router.$init = function () {
	const properRouter = new Router({
		mode: 'history',
		routes: routes
	})
	Object.assign(router, properRouter)
	router.initialized = true
}

export default router

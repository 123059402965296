import EventEmitter from 'events'
import Auth0Lock from 'auth0-lock'
import jwtDecode from 'jwt-decode'
import moment from 'moment'
import config from 'config'

const auth = {
	lock: null,
	error: null,
	init (options = {isSignUp: false, prefillEmail: null}) {
		const auth0Config = {
			auth: {
				params: {
					scope: 'openid email name nickname picture app_metadata is_first_login'
				},
				redirectUrl: window.location.origin,
				responseType: 'token',
				autoParseHash: false
			},
			container: 'auth0-login-container',
			theme: {
				primaryColor: '#0a974b'
			},
			languageDictionary: {
				title: 'NLG Cloud Login',
				signUpTerms: 'By signing up,<br/>you agree to our <a href="https://assets.ax-semantics.com/terms-and-conditions.pdf" target="_blank">terms and conditions</a>.'
			},
			additionalSignUpFields: [{
				name: 'given_name',
				placeholder: 'your first name',
				validator: () => true
			}, {
				name: 'family_name',
				placeholder: 'your last name',
				validator: () => true
			}, {
				name: 'company_name',
				placeholder: 'your company name',
				validator: () => true
			}]
		}

		auth.lock = new Auth0Lock(config.auth.clientID, config.auth.domain, auth0Config)
		return new Promise((resolve, reject) => {
			auth.lock.resumeAuth(window.location.hash, (error, authResult) => {
				if (error) console.warn('auth0 error', error)
				auth.error = error
				if (authResult) {
					auth._saveAuth(authResult.idToken, authResult.idTokenPayload)
					auth.isFirstLogin = authResult.idTokenPayload.is_first_login // don't persist in localStorage
					auth.emit('authenticated')
				} else if (!error && 'id_token' in localStorage) {
					const idToken = localStorage.id_token
					const decodedToken = jwtDecode(idToken)
					// check expiry
					const expiry = moment.unix(decodedToken.exp)
					const profile = localStorage.profile ? JSON.parse(localStorage.profile) : decodedToken
					const soon = moment().add(30, 'minutes')
					if (expiry.isAfter(soon)) {
						auth._saveAuth(idToken, profile)
						auth.emit('authenticated')
					} else {
						localStorage.removeItem('id_token')
						console.warn('token expired')
					}
				}
				resolve()
			})

			// handle "last time you logged in"
			auth.lock.on('authenticated', function (authResult) {
				auth._saveAuth(authResult.idToken, authResult.idTokenPayload)
				auth.emit('authenticated')
			})
		})
	},
	_saveAuth (idToken, profile) {
		auth.token = idToken
		auth.profile = profile
		localStorage.setItem('id_token', idToken)
		localStorage.setItem('profile', JSON.stringify(profile))
	},
	logout (redirectUrl) {
		localStorage.removeItem('id_token')
		let returnUrl = window.location.origin
		if (redirectUrl) {
			returnUrl += redirectUrl
		}
		// TODO generate from config
		window.location.href = 'https://ax-semantics.eu.auth0.com/v2/logout?client_id=bufw8P1GSHgOO5g1QVn7U3hq2HoZJSHW&returnTo=' + encodeURIComponent(returnUrl)
	},
	updateProfile (profile) {
		localStorage.setItem('profile', JSON.stringify(profile))
	}
}

Object.assign(auth, EventEmitter.prototype)
export default auth

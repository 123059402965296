const mutations = {
	PUSH_ERROR (state, error) {
		state.errors.push(
			{
				timestamp: Date.now(),
				timestamp_hr: new Date(),
				error: error
			}
		)
	}
}
export default mutations

import createDocDefinition from 'lib/api/audit-helper/pdfstyle'
import store from 'store'

function createPDF (filename) {
	const pdfMake = require('pdfmake/build/pdfmake.js')
	pdfMake.fonts = {
		RobotoCondensed: {
			normal: 'RobotoCondensed-Regular.ttf',
			bold: 'RobotoCondensed-Bold.ttf',
			italics: 'RobotoCondensed-Italic.ttf',
			bolditalics: 'RobotoCondensed-BoldItalic.ttf',
			light: 'RobotoCondensed-Light.ttf',
			lightitalics: 'RobotoCondensed-LightItalic.ttf'
		}
	}
	if (pdfMake.vfs === undefined) {
		var pdfFonts = require('@/assets/fonts/vfs_fonts.js')
		pdfMake.vfs = pdfFonts.pdfMake.vfs
	}
	const form = store.getters['audit/get_auditForm']
	// form should contain the actual creation date
	form.audit_date = new Date().toISOString()
	return pdfMake.createPdf(createDocDefinition(form, filename))
}

function pdfActions (action) {
	const filename = store.getters['audit/get_filename']
	const dbid = store.getters['audit/get_auditForm'].dbid
	const pdfObj = createPDF(filename)
	switch (action) {
		case 'open':
			pdfObj.open()
			break
		case 'download':
			pdfObj.download(filename)
			break
		case 'storePdf':
			return pdfObj
		case 'renewPdfLink':
			store.dispatch('audit/renew_pdflink', dbid)
			break
		default:
			alert('selected case not implemented')
	}
}

export function openPDF () {
	return pdfActions('open')
}

export function downloadPDF () {
	return pdfActions('download')
}

export function storePDF () {
	return pdfActions('storePdf')
}

export function renewPdfLink () {
	return pdfActions('renewPdfLink')
}

import forIn from 'lodash/forIn'

const messagingModule = {
	namespaced: true,
	state: {
		messages: [],
		errors: []
	},
	mutations: {
		CREATE_MESSAGE (state, message) {
			state.messages.push(message)
		},
		DELETE_MESSAGE (state, message) {
			state.messages.splice(state.messages.indexOf(message), 1)
		},
		GENERIC_REQUEST_FAILED (state, error) {
			if (typeof error.json === 'object') {
				forIn(error.json, function (value, key) {
					if (key === 'message') {
						state.errors.push({
							timestamp: Date.now(),
							message: value
						})
					} else {
						state.errors.push({
							timestamp: Date.now(),
							message: key + ': ' + value
						})
					}
				})
			} else {
				state.errors.push({
					timestamp: Date.now(),
					message: error.json
				})
			}
		},
		PUSH_ERROR (state, error) {
			state.errors.push({
				timestamp: Date.now(),
				message: error.message
			})
		},
		DISMISS_ERROR (state, error) {
			state.errors.splice(state.errors.indexOf(error), 1)
		},
	},
	actions: {
		'create-message' ({commit}, message) {
			if (!message.timestamp)
				message.timestamp = Date.now()
			commit('CREATE_MESSAGE', message)
			setTimeout(() => {
				commit('DELETE_MESSAGE', message)
			}, 6000)
		}
	}
}

export default messagingModule

import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const cleanState = function () {
	return {
		errors: []
	}
}

const errorModule = {
	namespaced: true,
	state: cleanState(),
	actions,
	mutations,
	getters
}

export default errorModule
export { cleanState }

function filterAudits (audits) {
	const uniqueAudits = {}
	audits.forEach((audit) => {
		audit.result.dbid = audit.id
		if (!uniqueAudits[audit.result.id]) {
			uniqueAudits[audit.result.id] = audit.result
		} else {
			const x = Date.parse(uniqueAudits[audit.result.id].audit_date)
			const y = Date.parse(audit.result.audit_date)
			if (y > x)
				uniqueAudits[audit.result.id] = audit.result
		}
	})
	return Object.values(uniqueAudits).sort(
		function (a, b) {
			const x = new Date(a.audit_date)
			const y = new Date(b.audit_date)
			return y - x
		}
	)
}

function createBranchOptions (auditlist) {
	const branches = []
	for (let i = 0; i < auditlist.length; i++) {
		branches.push(
			{
				id: i + 1,
				branch: auditlist[i].result.branch,
				type: auditlist[i].result.branchtype,
				case: auditlist[i].result.branchcase,
				projectname: auditlist[i].result.projectname
			}
		)
	}
	return {
		selectedBranch: 1,
		branches: branches
	}
}

export { filterAudits, createBranchOptions }

<template lang="pug">
	.form-group
		.form-section(v-if="form")
			p Lights
			svg.lights
				rect(:fill="form[section].colors.flags" )
			svg.lights
				rect(:fill="form[section].colors.gates" )
			svg.lights
					rect(:fill="form[section].colors.success" )
			p Flags
				auditCheckbox(
					v-for="(option, index) in templatedata[section].flags"
					:key="index"
					v-model="form[section].flags"
					:name="section + `flags`"
					:index="index"
					:label="option"
					:option="option"
					@input="$emit('qa:task', 'default')"
					)
			p Quality Gates
				auditCheckbox(
					v-for="(option, index) in templatedata[section].gates"
					:key="index"
					v-model="form[section].gates"
					:name="section + `gates`"
					:index="index"
					:label="option"
					:option="option"
					@input="$emit('qa:task', 'default')"
					)
			p Success Factors
				auditCheckbox(
					v-for="(option, index) in templatedata[section].success"
					:key="index"
					v-model="form[section].success"
					:name="section + `success`"
					:index="index"
					:label="option"
					:option="option"
					@input="$emit('qa:task', 'default')"
					)
			.actionItem
				p Action items
				textarea(v-model="form[section].action_items" placeholder="editme" cols="80" rows="5" readonly)
			.actionShow
					auditCheckbox(
						v-model="form[section].action_show"
						:name="section + `hide`"
						:label="`Hide action items`"
						:index=0
						:option="form[section].action_show"
						@input="$emit('qa:task', 'colorize')"
						)

</template>
<script>
import auditCheckbox from 'components/audit/auditcheckbox'
import { mapGetters } from 'vuex'

export default {

	props: {
		section: {
			type: String,
			required: true
		},
		forms: {
			type: Object,
			required: false
		}
	},
	components: {
		auditCheckbox
	},
	computed: {
		...mapGetters({
			form: 'audit/get_auditForm'
		}),
		templatedata () { return this.$store.state.audit.templatedata },
	},
	watch: {
		'form.usecase.gates': {
			// stupid workaround...because of woopra and no time
			// better change data format of the formdata and formtemplate
			// change schema in woopra
			// write new compotent: auditform with buntselect
			// fix api calls, if buntselect is used it generates 6 requests isntead of 1
			handler: function (newval) {
				if (newval.includes('proven usecase')) {
					this.form.branchtype = 'proven usecase'
				} else if (newval.includes('familiar usecase')) {
					this.form.branchtype = 'familiar usecase'
				} else if (newval.includes('new usecase')) {
					this.form.branchtype = 'new usecase'
				} else {
					this.form.branchtype = ''
				}
			},
			deep: true,
			immediate: true
		}
	}
}
</script>

<style lang="stylus">
@import '~_settings';
.form-group
	flex: 1
	display: flex
	flex-direction: column
	padding: 16px
	position: relative
	flex: none
	margin: 0 0
	padding: 0 0
	border-bottom: border-separator()
	p:first-of-type
		font-weight: bold
	.form-section
		padding: 0 0 10px 0
		p:first-of-type
			font-weight: bold

.color-summary
	display: flex
	flex-wrap: wrap
	justify-content: space-between
	.box
		flex: 0 32%;
		height: auto;
		p
			margin: 0 0

.lights
	width: 20px
	height: 20px
	rect
		width: 100%
		height: 100%
		stroke-width: 3
		stroke rgb(0, 0, 0)

</style>

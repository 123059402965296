import { filterAudits } from 'lib/api/audit-helper/audit-usecasematrix'
import nameFile from 'lib/api/audit-helper/filename'

const getters = {
	ready (state) {
		return !!state.user && !!state.audits
	},
	loaded (state, getters) {
		return !!getters.ready && !!state.form
	},
	get_filteredAudits (state, getters) {
		return getters.ready && state.audits.length > 0
			? filterAudits(state.audits)
			: []
	},
	get_uniqueProjects (state, getters) {
		return getters.ready ? getters.get_filteredAudits.length : 0
	},
	get_latestAudit (state, getters) {
		return getters.ready ? getters.get_filteredAudits[0] : 0
	},
	get_nextAuditId (state, getters) {
		return state.nextAuditId === 0 ? 1 : state.nextAuditId
	},
	get_userpdfstoreid (state) {
		return state.pdfStroreId
	},
	get_username (state) {
		return state.user.name
	},
	get_useremail (state) {
		return state.user.email
	},
	get_templatedata (state) {
		return state.templatedata
	},
	get_auditList (state, getters) {
		let id = 1
		// first element always the template
		// this template will never be send to db,
		const auditList = [{
			id: 0,
			auditid: 0,
			projectname: 'New Project',
			branch: 'new usecase',
			type: 'no type',
			case: 'no case',
		}]
		if (!!getters.get_filteredAudits && getters.get_filteredAudits.length > 0) {
			getters.get_filteredAudits.forEach(audit => {
				auditList.push(
					{
						id: id++,
						auditid: audit.id,
						branch: audit.branch,
						type: audit.branchtype,
						case: audit.branchcase,
						projectname: audit.projectname
					}
				)
			})
			return auditList
		}
		return auditList
	},
	get_auditForm (state) {
		return state.form
	},
	// TODO: better approach to get the projectname from computed
	get_filename (state, getters) {
		return nameFile(getters.get_username, getters.get_auditForm.projectname)
	},
	get_amountProjectObj (state, getters) {
		return {
			amount_audited_projects: getters.get_auditForm.id,
			datetime: new Date().toISOString(),
			new_auditid: getters.get_auditForm.id,
			new_usecase_cat: getters.get_auditForm.branch,
			new_usecase_subcat: getters.get_auditForm.branchcase,
			new_usecase_type: getters.get_auditForm.branchtype
		}
	}
}

export default getters

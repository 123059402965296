const formTemplate = {
	id: null,
	pdf: null,
	workbook: '',
	email: null,
	audit_date: null,
	audit_cause: '',
	churn_reason: '',
	branch: '',
	branchtype: '',
	branchcase: '',
	errors: [],
	usecase: {
		colors: {
			flags: 'white',
			gates: 'white',
			success: 'white'
		},
		flags: [],
		gates: [],
		success: [],
		action_items: '',
		action_show: false
	},
	data: {
		colors: {
			flags: 'white',
			gates: 'white',
			success: 'white'
		},
		flags: [],
		gates: [],
		success: [],
		action_items: '',
		action_show: false
	},
	team: {
		colors: {
			flags: 'white',
			gates: 'white',
			success: 'white'
		},
		flags: [],
		gates: [],
		success: [],
		action_items: '',
		action_show: false
	},
	atml: {
		colors: {
			flags: 'white',
			gates: 'white',
			success: 'white'
		},
		flags: [],
		gates: [],
		success: [],
		action_items: '',
		action_show: false
	},
	roadmap: {
		colors: {
			flags: 'white',
			gates: 'white',
			success: 'white'
		},
		flags: [],
		gates: [],
		success: [],
		action_items: '',
		action_show: false
	},
	adoption: {
		colors: {
			flags: 'white',
			gates: 'white',
			success: 'white'
		},
		flags: [],
		gates: [],
		success: [],
		action_items: '',
		action_show: false
	},
	agent: '',
	sr: '',
	projectname: '',
	summary: '',
	recommendation: '',
	recommendation_text: ''
}

export default formTemplate

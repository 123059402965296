<template lang="pug">
	.form-group
		.form-section(v-if="form" @change="onChange()")
			p Project Information
			bunt-input(name="projectname" v-model="form.projectname" placeholder="Projectname")
		.form-section
			p Enter or change the name(customer)
			bunt-input(name="customerName" v-model="form.name" placeholder="Enter Name")
		#branches
			bunt-select#branch(name="branch" label="Usecase" v-model="form.branch" :options="templatedata.branch_options")
			bunt-select#branchCase(name="branchCase" label="Select Category" v-model="form.branchcase" :options="templatedata.branchcase_options")
		.form-section
			p Audit Cause
			.radio(v-for="(cause, index) in templatedata.audit_cause", :key="`${index}`" )
				input.audit_cause(type="radio", :id="`${index}`", v-model="form.audit_cause", :value="cause" )
				label(for="`${index}`") {{ cause }}
		.form-section
				p Customer Success Agent
				bunt-select#csa(name="agent" label="Select an agent" v-model="form.agent" :options="templatedata.agents")
		.form-section
				p Sales Representative
				bunt-select#sr(name="sr" label="Select a sales representative(email)" v-model="form.sr" :options="templatedata.sr")

</template>
<script>
import { mapGetters } from 'vuex'

export default {
	data () {
		return {
		}
	},
	computed: {
		...mapGetters({
			form: 'audit/get_auditForm',
			templatedata: 'audit/get_templatedata'
		})
	},
	methods: {
		onChange () {
			this.$store.commit('audit/SET_AUDITFORM', this.form)
		}
	}
}
</script>

<style lang="stylus">
@import '~_settings';
.form-group
	flex: 1
	display: flex
	flex-direction: column
	padding: 16px
	position: relative
	flex: none
	margin: 0 0
	padding: 0 0
	border-bottom: border-separator()
	p:first-of-type
		font-weight: bold
	.form-section
		padding: 0 0 10px 0
		p:first-of-type
			font-weight: bold

.color-summary
	display: flex
	flex-wrap: wrap
	justify-content: space-between
	.box
		flex: 0 32%;
		height: auto;
		p
			margin: 0 0

.lights
	width: 20px
	height: 20px
	rect
		width: 100%
		height: 100%
		stroke-width: 3
		stroke rgb(0, 0, 0)

</style>

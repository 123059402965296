<template lang="jade">
#v-app.login
	.logo
		img(src="~assets/images/logo-support.svg")
	#auth0-login-container
</template>
<script>
import auth from 'lib/api/auth'
export default {
	components: {},
	mounted () {
		this.$nextTick(() => {
			if (auth.error) {
				auth.lock.show({
					flashMessage: {
						type: 'error',
						text: auth.error.error_description
					}
				})
			} else {
				auth.lock.show()
			}
		})
	}
}
</script>
<style lang="stylus">
@import '~_settings'

.login
	height 100%
	display flex
	justify-content center
	align-items center
	flex-direction column
	#auth0-login-container
		flex: 0 0 auto
	.auth0-lock-header
		display none
	.auth0-lock.auth0-lock // das muss so
		z-index: 0
		.auth0-lock-widget
			border-radius: 0
			max-height: none
		.auth0-lock-cred-pane
			border-radius 0 0 6px 6px

		.auth0-lock-input-show-password
			margin-bottom: 10px

	.logo
		display flex
		justify-content center
		align-items baseline
		width 300px
		background-color $clr-white
		padding 16px 0
		border-radius 6px 6px 0 0
		img
				height: 52px
</style>

import api from 'lib/api'
import config from 'config'
import { storePDF } from 'lib/api/audit-helper/pdfhelpers'
import { transform as formWoopra } from 'lib/api/audit-helper/format-woopra'
import cloneDeep from 'lodash/cloneDeep'

import woopra from 'lib/api/woopra'

const actions = {
	fetch_audits ({ commit, getters, dispatch }, email) {
		return api.quest.getAudit(email)
			.then(response => {
				commit('SET_AUDITS', response)
				if (response.length > 0) {
					commit('SET_SELECTEDAUDIT', 1)
					dispatch('set_auditform', 1)
				} else {
					commit('SET_SELECTEDAUDIT', 0)
					dispatch('set_auditform', 0)
				}
			})
			.catch((response) => {
				commit('SET_AUDITS', undefined)
			})
	},
	fetch_pdfStoreUserId ({ commit }, usermail) {
		return api.quest.getUser(usermail)
			.then((response) => commit('SET_PDFSTORE_ID', response.id))
			.catch(() => commit('SET_PDFSTORE_ID', undefined))
	},
	set_user ({ commit, dispatch }, user) {
		if (user) {
			commit('SET_USER', user)
			dispatch('fetch_pdfStoreUserId', user.email)
			dispatch('fetch_audits', user.email)
		} else {
			console.log('action set_user: user not set')
		}
		return Promise.resolve()
	},
	set_auditform ({ commit, dispatch, state, getters }, auditListIdx) {
		if (auditListIdx > 0) {
			// auditList: first element is a template entry, therefore substract it
			commit('SET_AUDITFORM', Object.assign({}, getters.get_filteredAudits[auditListIdx - 1]))
			dispatch('set_nextAuditId')
		} else {
			dispatch('set_nextAuditId').then(() => {
				const newform = cloneDeep(state.template)
				newform.id = getters.get_nextAuditId
				newform.email = getters.get_useremail
				newform.name = getters.get_username
				commit('SET_AUDITFORM', Object.assign({}, newform))
			})
		}

		return Promise.resolve()
	},
	set_nextAuditId ({ state, commit, getters }) {
		let nextID = 0
		const lenUniqP = getters.get_uniqueProjects
		const latestAuditId = getters.get_latestAudit?.id

		if (lenUniqP > 0) {
			if (latestAuditId > lenUniqP) {
				nextID = latestAuditId + 1
			} else {
				nextID = lenUniqP + 1
			}
		}
		commit('SET_NEXTAUDITID', nextID)
		return Promise.resolve()
	},
	set_uploadtime ({ state, commit, getters }) {
		const form = getters.get_auditForm
		form.datetime = new Date().toISOString()
		commit('SET_AUDITFORM', Object.assign({}, form))
	},
	set_submitStatus ({ state, commit, getters }, status) {
		const form = getters.get_auditForm
		form.submit = status === 'submit'
		commit('SET_AUDITFORM', Object.assign({}, form))
	},
	renew_pdflink ({ state, commit, getters }) {
		const form = getters.get_auditForm
		api.quest.getPdfLink(form.dbid).then(
			res => {
				form.pdfLink = res.download_url
				commit('SET_AUDITFORM', Object.assign({}, cloneDeep(form)))
			}
		)
	},
	save_audit ({ state, commit, getters, dispatch }, validation) {
		const form = getters.get_auditForm
		form.audit_date = new Date().toISOString()
		if (!(form.audit_cause === 'Churn' || form.recommendation === 'confirm_cancel')) {
			form.churn_reason = ''
		}
		const isNewProject = getters.get_nextAuditId === form.id
		const dbFormat = {
			user: getters.get_userpdfstoreid || '',
			result: form
		}
		return api.quest.saveAudit(dbFormat)
			.then(response => {
				if (response.error) {
					console.log('Failed to save the audit.')
				}
				if (isNewProject) {
					dispatch('send_woopraNewProjectInfo')
				}
				return Promise.resolve(response)
			})
	},
	send_woopraFormdata ({ state, getters }) {
		woopra.identify(getters.get_useremail)
		woopra.track(config.woopra.qualitygate, formWoopra(getters.get_auditForm))
		// TODO: woopra api error handling
		return Promise.resolve()
	},
	send_woopraNewProjectInfo ({ state, getters }) {
		// update woopra if an audit was created for a NEW project
		woopra.identify(getters.get_useremail)
		// check
		if (getters.get_auditForm.id > getters.get_uniqueProjects) {
			woopra.track(config.woopra.amountprojects, getters.get_amountProjectObj)
		}
		// TODO: woopra api error handling
		return Promise.resolve()
	},
	store_pdf ({ state, commit, getters, dispatch }, dbid) {
		return new Promise((resolve, reject) => {
			storePDF().getBlob(blob => {
				const file = new File([blob], getters.get_filename, { type: blob.type })
				api.quest.storepdf(dbid, getters.get_filename, file).then(response => resolve(response), response => reject(response))
			})
		})
	},
	send_recommendations ({ state, getters }, pdfapiresponse) {
		const form = getters.get_auditForm
		if (pdfapiresponse && pdfapiresponse.download_url) {
			fetch(config.zapier.baseUrl, {
				method: 'POST',
				body: JSON.stringify(
					{
						email: form.email,
						sr: form.sr,
						csa: form.agent,
						recommendation: form.recommendation,
						churn_reason: form.churn_reason,
						pdflink: pdfapiresponse.download_url,
						usecase: form.branch,
						usecase_category: form.branchcase,
						usecase_type: form.branchtype,
						auditedProjects: getters.get_uniqueProjects
					}
				)
			}
			)
			return true
		} else {
			return false
		}
	}
}

export default actions

import Vue from 'vue'
import Vuex from 'vuex'
import mutations from './mutations'
import getters from './getters'
import actions from './actions'
import messaging from './modules/messaging'
import audit from './modules/audit'

// this needs to be here and not in main.js, because imports get all hoisted ¬_¬
Vue.use(Vuex)

const state = {
	user: {
		authenticated: false,
		token: null,
		refreshToken: null,
		apiToken: null,
		profile: null,
		groups: [],
		sessionExpiresIn: 99999
	},
	users: null,
	activeUser: null
}

const store = new Vuex.Store({
	modules: {
		messaging,
		audit
	},
	state,
	mutations,
	getters,
	actions
})

setInterval(() => {
	store.commit('COMPUTE_SESSION_EXPIRY')
}, 60000)

export default store

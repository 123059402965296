import config from 'config'
import store from 'store'
import AxSemanticsClient from 'axsemantics'

const api = {
	idm: null,
	init (token) {
		api._axsemantics = new AxSemanticsClient({
			idmBaseUrl: config.api.idm.baseUrl,
			questBaseUrl: config.api.quest.baseUrl,
			idToken: token,
			fetch: function () {
				return AxSemanticsClient.fetch.apply(this, arguments).then((arg) => {
					return Promise.resolve(arg)
				}).catch((error) => {
					if (error.response && (error.response.status < 400 || error.response.status > 499)) { // let the caller handle 4xx
						store.commit('messaging/GENERIC_REQUEST_FAILED', error)
					}
					return Promise.reject(error)
				})
			}
		})

		api.idm = api._axsemantics._idm
		api.quest = api._axsemantics._quest
		api.quest.getPdfLink = (auditID) => {
			return api.quest.fetch(`v1/qualityaudit/${auditID}/audit_pdf/`, 'GET')
		}

		api.quest.storepdf = (auditID, filename, pdfFile) => {
			// FIXME: handle userHeaders in AxSemanticsClient
			const headers = {
				Accept: 'application/json',
				Authorization: `JWT ${api._axsemantics.idToken}`
			}
			const formData = new FormData()
			formData.append('audit_pdf', pdfFile)
			formData.append('filename', filename)
			return api.quest.fetch(`v1/qualityaudit/${auditID}/audit_pdf/`, 'POST', formData, headers)
		}
		api.quest.getAudit = (user) => {
			// user is an email
			const qs = new URLSearchParams({ user }).toString()
			const response = api.quest.fetch(`v1/qualityaudit/?${qs}`)
			return response
		}
		api.quest.saveAudit = (data) => {
			return api.quest.fetch('v1/qualityaudit_zapier/', 'POST', data)
		}

		api.quest.getUser = (email) => {
			const qs = new URLSearchParams({ email }).toString()
			return api.quest.fetch(`v1/users/?${qs}`)
		}

		api.quest.generateText = (data) => {
			return api.quest.fetch('v1/qualityaudit/generate_text/', 'POST', data)
		}
		api.idm.users.list = () => {
			return api.idm.fetch('userlist/')
		}
		api.idm.users.get = (id) => {
			return api.idm.fetch(`userlist/${id}/`)
		}
		api.idm.users.impersonate = (id) => {
			return api.idm.fetch(`userlist/${id}/impersonate/`)
		}
		api.idm.users.intercom = (id) => {
			return api.idm.fetch(`userlist/${id}/intercom/`)
		}
		api.responseToObject = (response) => {
			return response.reduce((result, item, index, array) => {
				result[item.slug] = item
				return result
			}, {})
		}
	}
}

window.api = api // for console debug
export default api

<template lang="pug">
	.auditform(v-if="newform")
		form(@submit.prevent="submitForm")
			bunt-tabs.tabs-default(:active-tab="activeTab" )
				bunt-tab#General(header="General", @selected="activeTab = 'General'")
					auditGeneral()
				bunt-tab#Usecases(header="Usecases", @selected="activeTab = 'Usecases'")
					auditQualityGate(section='usecase' @qa:task="task")
				bunt-tab#Data(header="Data", @selected="activeTab = 'Data'")
					auditQualityGate(section="data" @qa:task="task")
				bunt-tab#Team(header="Team", @selected="activeTab = 'Team'")
					auditQualityGate(section="team" @qa:task="task")
				bunt-tab#ATML(header="ATML", @selected="activeTab = 'ATML'")
					auditQualityGate( section="atml" @qa:task="task")
				bunt-tab#Roadmap(header="Roadmap", @selected="activeTab = 'Roadmap'")
					auditQualityGate( section="roadmap" @qa:task="task")
				bunt-tab#Adoption(header="Adoption", @selected="activeTab = 'Adoption'")
					auditQualityGate( section="adoption" @qa:task="task")
				bunt-tab#Summary(header="Summary", @selected="activeTab = 'Summary'")
					auditSummary(:v="$v.newform" @qa:task="task")
</template>
<script>
import rules from 'lib/api/audit-helper/validation-rules/validation-rules'
import api from 'lib/api'
import auditGeneral from 'components/audit/auditgeneral'
import auditQualityGate from 'components/audit/auditqualitygate'
import auditSummary from 'components/audit/auditsummary'

import { colorize } from 'lib/api/audit-helper/audit-colorize'
import { mapGetters } from 'vuex'

export default {
	components: {
		auditQualityGate,
		auditGeneral,
		auditSummary
	},
	data () {
		return {
			activeTab: null,
			forms: this.loaded_forms,
			formloaded: true,
		}
	},
	validations () {
		return {
			newform: this.rules
		}
	},
	watch: {
		newform () {
			colorize(this.newform)
		}
	},
	computed: {
		...mapGetters({
			newform: 'audit/get_auditForm',
		}),
		rules () {
			if (this.newform.audit_cause === 'Churn' || this.newform.recommendation === 'cancel') {
				return rules.churn
			}
			if (this.newform.recommendation === 'start_workbook') {
				return rules.workbook
			}
			return rules.general
		}
	},
	created () {
	},
	mounted () {
	},
	methods: {
		task (event) {
			switch (event) {
				case 'generate':
					this.generate_text()
					break
				case 'colorize':
					colorize(this.newform)
					break
				case 'default':
					this.generate_text()
					colorize(this.newform)
			}
		},
		generate_text () {
			const data = {
				recommendation: this.newform.recommendation,
				usecase: this.newform.usecase,
				data: this.newform.data,
				atml: this.newform.atml,
				team: this.newform.team,
				roadmap: this.newform.roadmap,
				adoption: this.newform.adoption,
			}
			api.quest.generateText(data).then(res => {
				const actionText = res.text.split('%%%divider%%%')
				this.newform.usecase.action_items = actionText[0]
				this.newform.data.action_items = actionText[1]
				this.newform.team.action_items = actionText[2]
				this.newform.atml.action_items = actionText[3]
				this.newform.roadmap.action_items = actionText[4]
				this.newform.adoption.action_items = actionText[5]
				this.newform.recommendation_text = actionText[7]
			}
			)
		},
	},
	destroyed () {
		this.$store.dispatch('audit/set_auditform', 0)
	}
}
</script>

<style lang="stylus">
@import '~_settings';
.audit-form
	width: 100%

.tabs-default
	tabs-style()
	width: 100%

</style>

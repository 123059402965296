import { required } from 'vuelidate/lib/validators'

const churnNoContact = {
	churn_reason: {
		required
	},
	audit_cause: {
		required
	},
	agent: {
		required
	},
	summary: {
		required
	},
	recommendation: {
		required
	}
}

export default churnNoContact

export function transform (oldform) {
	const form = oldform

	delete form.summary
	delete form.recommendation_text

	delete form.usecase.action_items
	delete form.data.action_items
	delete form.team.action_items
	delete form.atml.action_items
	delete form.roadmap.action_items
	delete form.adoption.action_items
	delete form.id
	delete form.pdf

	function flattenForm (form) {
		const flatForm = {}
		for (const key in form) {
			if (!Object.prototype.hasOwnProperty.call(form, key)) continue
			if (Array.isArray(form[key])) {
				flatForm[key] = form[key].join(';')
			} else if ((typeof form[key]) === 'object') {
				const flatObject = flattenForm(form[key])
				for (const key2 in flatObject) {
					if (!Object.prototype.hasOwnProperty.call(flatObject, key2)) { continue }
					flatForm[key + '_' + key2] = flatObject[key2]
				}
			} else {
				flatForm[key] = form[key]
			}
		}
		return flatForm
	}

	return flattenForm(form)
}

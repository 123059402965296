import { required, url } from 'vuelidate/lib/validators'
import general from './general'

const woorkbook = {
	...general,
	workbook: {
		url,
		required
	}

}

export default woorkbook

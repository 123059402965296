
const formData = {
	workbook: '',
	audit_cause: ['Kickoff Call', 'Revision Call', 'Post Onboarding', 'Churn'],
	branch_options: ['E-Commerce', 'Publishing', 'Finance', 'Other', 'Socialmedia', 'Onlineservice', 'Newsstream'],
	branchtype_options: ['proven usecase', 'familiar usecase', 'new usecase'],
	summary: '',
	recommendation_text: '',
	recommendation: '',
	branchcase_options: [
		'Product text',
		'Product text (Intl)',
		'Category text',
		'News report',
		'Chatbot',
		'Summary',
		'Contract',
		'Ratings',
		'Regulatory',
		'Newsstream',
		'Landingpages'
	],
	agents: [
		'Alexandra Waldleitner',
		'Niklas Schwibbe',
		'Nyamsuren Davaasambuu',
		'Paz Huete Iglesias',
		'Peter Schmitt',
		'Vitaliia Voronik'
	],
	sr: [
		'thomas.miltschuh@ax-semantics.com',
		'utkarsh.nagaraj@ax-semantics.com',
		'moe.sayed@ax-semantics.com',
		'mehdi.sayed@ax-semantics.com',
		'volkan.efe@ax-semantics.com'
	],
	churn_reasons: [
		'no successful contact',
		'no willingness to pay',
		'no show',
		'no development team',
		'no usecase',
		'want only to test',
		'no time',
		'missing data',
		'not known',
		'too expensive',
		'covid 19',
		'wants to come back later',
		'other expectations',
		'moved to new subscription'
	],
	recommendation_normal_options: [
		'force_cancel',
		'force_digitalization_fastlane',
		'offer_3_month_adoption_project',
		'offer_service_creation_package',
		'force_noncommercial_license',
		'force_expansion_process',
		'continue_with_license',
		'start_workbook'
	],
	recommendation_churn_options: [
		'confirm_cancel',
		'leave_license_unchanged',
		'force_schedule_rescue_call',
		'offer_service_creation',
		'offer_noncommercial',
		'offer_free_month'
	],
	branch: '',
	branchtype: '',
	branchcase: '',
	usecase: {
		colors: {
			flags: 'white',
			gates: 'white',
			success: 'white'
		},
		flags: [
			'is about generating blog content',
			'is about trying out the technology',
			'is to acquire a customer, then get familiar with AX Semantics',
			'is to generate an exact amount of words for SEO applications',
			'customer seems uninterested in realizing an actual usecase',
			'customer does not know how to measure the success of his usecase',
			'customer has invoices in dunning',
			'customer has objections about his license price',
			'the output channels cannot accomodate the generated content'
		],
		gates: [
			'proven usecase',
			'familiar usecase',
			'new usecase'
		],
		success: [
			'The customer does know how to measure the success of his usecase',
			'customer has a usecase with recurring need for text at least once a month'
		],
		action_items: '',
		action_show: false
	},
	data: {
		colors: {
			flags: 'white',
			gates: 'white',
			success: 'white'
		},
		flags: [
			'there is no data',
			'data needs a transformation',
			'data needs a transformation for category descriptions',
			'customer has no access to people who own his data',
			'infrastructure not API compatible',
			'the customer is not able to upload his data continuously',
			'the customer is not able to download his content continuously',
			'the data is too incomplete for the usecase',
			'Financial, health and manufacturing: It is unsure if customer has permission to use the data productively'
		],
		gates: [
			'there is usable data',
			'data is uploadable in AX Semantics',
			'the customer has someone in his team who can connect to the API',
			'the customer can accomodate text in his output channels',
			'the data allows for using the histogram (numeric distribution/Analyze)',
			'Financial, health and manufacturing: Customer has permission to use the data productively'
		],
		success: [
			'data can be used without changes',
			'there is an API integration with AX Semantics',
			'the data allows for using the histogram',
			'Financial, health and manufacturing: We have permission to use the data productively',
			'The data has the right aggregation for category descriptions'
		],
		action_items: '',
		action_show: false
	},
	team: {
		colors: {
			flags: 'white',
			gates: 'white',
			success: 'white'
		},
		flags: [
			'there is no one who will own the content generation project',
			'customer does AX Semantics after his actual job',
			'team lacks logic skills',
			'team lacks authoring or grammar skills',
			'customer is ghosting CSA / going unreachable',
			"customer can't communicate in the CSAs' languages",
			'there is only one team member who can operate AX Semantics'
		],
		gates: [
			'customer can name a person who will operate AX',
			'there is a person that can learn AX Semantics with reasonable effort',
			'customer knows his stakeholders',
			'customer knows well how to use internal infrastructure',
			"customer can decide about this project's steps himself"
		],
		success: [
			'there are several team members who can operate AX Semantics',
			'the customer has additional service creation from a partner'
		],
		action_items: '',
		action_show: false
	},
	atml: {
		colors: {
			flags: 'white',
			gates: 'white',
			success: 'white'
		},
		flags: [
			'no stories can be generated yet',
			'there is little to nothing in the training yet',
			'customer seems lost when trying to conceptualize his ideas',
			'customer seems lost when trying to implement his ideas'
		],
		gates: [
			'modular sentences can be seen',
			'transformer best practices are being used',
			'there are an adequate number of branches (variants and synonyms) for most sentences',
			'test objects are being used'
		],
		success: [
			'there is a draft and published project',
			'analysis result node is being used',
			'text concept creates a highly variant output',
			'Customer comes up with their own complex logics or data modifications'
		],
		action_items: '',
		action_show: false
	},
	roadmap: {
		colors: {
			flags: 'white',
			gates: 'white',
			success: 'white'
		},
		flags: [
			'we are just testing',
			'there is an unrealistic deadline to have xy finished',
			'MVP is unrealistic',
			'The Project requires technological "magic" that AX does not provide'
		],
		gates: [
			'there is enough time to finish realistically',
			'there is an realistic deadline to have xy finished',
			'There is no milestone requiring technological "Magic"'
		],
		success: [
			'there are several topics on the roadmap',
			'there are several languages on the roadmap',
			'there are several text formats on the roadmap',
			'there are several channels on the roadmap',
			'the planning is agile instead of deadline-driven'
		],
		action_items: '',
		action_show: false
	},
	adoption: {
		colors: {
			flags: 'white',
			gates: 'white',
			success: 'white'
		},
		flags: [
			'no time to use',
			'did not do the Seminar',
			'customer needs support for defending his business case',
			'customer does not consider first text type publishable',
			'customer has no imagination what a next topic could be',
			'customer has implemented an AX topic, but claims it was not suitable for automation',
			'customer is dissatisfied with how the first project is / was going'
		],
		gates: [
			'customer finished the Seminar',
			'customer is doing the followup lessons or working with examples',
			'customer is logging in at least 3 days a week',
			'customer can answer which business metrics are relevant to him',
			'customer has a usecase with recurring need for updated content'
		],
		success: [
			'the colleagues are working with AX CS staff regularly',
			'the colleagues can decide about budgets',
			'customer is logging in on all workdays',
			'customer is onboarding additional colleagues',
			'customer is working with references or example guides',
			'customer is producing text several times a week',
			'customer is aware what revenue his stories generate',
			'customer has calculated a next topic',
			'customer has chosen a next topic'
		],
		action_items: '',
		action_show: false
	}
}

export default formData

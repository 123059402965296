import moment from 'moment'

const mutations = {
	AUTHENTICATE_USER (state, {token, profile, impersonation}) {
		state.user.authenticated = true
		state.user.token = token
		state.user.profile = profile
		state.user.impersonation = impersonation
	},
	UPDATE_USER (state, user) {
		const defaults = {
			introStage: '00-welcome',
			features: []
		}
		state.user.settings = Object.assign(defaults, user.settings)
		// when impersonating, don't show intros
		if (state.user.impersonation) {
			state.user.settings.introStage = '99-finished'
		}
		state.user.groups = user.groups
		state.user.invitations = user.invitations
		state.user.id = user.id
		state.user.refreshToken = user.refresh_token
	},
	UPDATE_API_TOKEN (state, tokenExchange) {
		state.user.apiToken = tokenExchange.id_token
	},
	COMPUTE_SESSION_EXPIRY (state) {
		if (!state.user.profile) return
		state.user.sessionExpiresIn = moment.unix(state.user.profile.exp).diff(moment(), 'minutes')
	},
	SET_USERS (state, users) {
		state.users = users
	},
	SET_QUESTS (state, quests) {
		state.quests = quests.reduce((result, item, index, array) => {
			result[item.slug] = item
			return result
		}, {})
	}
}

export default mutations

const config = {
	api: {
		idm: {
			baseUrl: 'https://idm.ax-semantics.com/v1/'
		},
		quest: {
			baseUrl: 'https://quest-api.ax-semantics.com/',
		},
	},
	auth: {
		clientID: 'bufw8P1GSHgOO5g1QVn7U3hq2HoZJSHW',
		domain: 'ax-semantics.eu.auth0.com'
	},
	cockpit: {
		baseUrl: 'https://cockpit.ax-semantics.com/',
	},
	translate: {
		baseUrl: 'https://translate.ax-semantics.com/',
	},
	intercom: {
		app_id: '56a54bf3818673484d09c5551b819d9daaedd377'
	},
	woopra: {
		qualitygate: 'audit_qualitygate',
		amountprojects: 'audit_amountprojects'
	},
	zapier: {
		baseUrl: 'https://hooks.zapier.com/hooks/catch/3246926/qaw0dv/silent/'
	}
}

export default config

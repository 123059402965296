export function colorize (form) {
	const categories = [
		'usecase',
		'data',
		'team',
		'atml',
		'roadmap',
		'adoption'
	]
	const subcategories = ['flags', 'gates', 'success']
	categories.forEach(category =>
		subcategories.forEach(subcategory => {
			form[category].colors[subcategory] = determineColor(
				form[category][subcategory],
				category,
				subcategory,
				form[category].action_show,
				form[category]
			)
		})
	)
}

function determineColor (list, category, subcategory, hide, content) {
	let length = 0
	if (list.length > 0) {
		length = list.length
	}
	let orange = 'orange'
	let x = 'green'
	let y = 'red'
	if (subcategory === 'flags') {
		x = 'red'
		y = 'green'
	}
	if (hide) {
		x = y = orange = 'lightgrey'
	}
	if (subcategory === 'gates' && category === 'usecase') {
		if (content.gates.includes('proven usecase')) { return x }
		if (content.gates === 'familiar usecase') { return orange }
		if (content.gates === 'new usecase') { return y }
	}
	if (length >= 2) {
		return x
	}
	if (length >= 1) {
		if (subcategory === 'flags' && category === 'usecase') {
			return x
		}
		return orange
	}
	if (length === 0) {
		return y
	}
}

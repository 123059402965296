<template lang="jade">
.c-users.bi-view
	.users-panel.left-panel
		.actions
			bunt-input.search(v-model="search", name="search", label="Search by name, email address, or group")
		.user-list(v-scrollbar.y="")
			.user(v-for="user in scrolledUsers")
				.profile
					img(:src="user.picture")
				.name(v-if="user.first_name") {{ user.first_name }} {{ user.last_name }}
				.name(v-else) {{ user.name }}
				.email {{ user.email }}
				.last(v-if="user.last_login") {{ user.last_login | fromnow }}
				.last(v-else) never
				bunt-link-button#btn-show-audit(:to="{name: 'audit', params: {id: user.id}}") Audit
				bunt-link-button#btn-show-user.user(:to="{name: 'user', params: {id: user.id}}") Info

			infinite-scroll(@infinite="onInfinite")

	.center-frame
			router-view(v-if="this.$route.name === 'audit'", :key="this.$route.params.id")
			router-view(v-else="this.$route.name === 'user'", :key="this.$route.params.id")

</template>
<script>
import { mapState } from 'vuex'
import fuzzysearch from 'fuzzysearch'
import InfiniteScroll from 'components/infinite-scroll'

export default {
	components: {InfiniteScroll},
	data () {
		return {
			search: '',
			scroll: 100
		}
	},
	computed: {
		...mapState(['users']),
		scrolledUsers () {
			return this.filteredUsers.slice(0, this.scroll)
		},
		filteredUsers () {
			const search = this.search.toLowerCase()
			return this.users.filter((user) => {
				return fuzzysearch(search, user.email.toLowerCase()) ||
					fuzzysearch(search, (user.name?.toLowerCase() || '')) ||
					fuzzysearch(search, (user.first_name?.toLowerCase() || '')) ||
					fuzzysearch(search, (user.last_name?.toLowerCase() || '')) ||
					user.groups?.map(group => group.name).join().toLowerCase().includes(search)
			})
		},
		emailFromRequest () {
			return this.$route.query.email
		}
	},
	created () {},
	mounted () {
		this.$nextTick(() => {
			if (this.emailFromRequest) {
				const userId = this.users.find(user => user.email === this.emailFromRequest)?.id
				if (userId) {
					this.search = this.emailFromRequest
					this.$router.push({ name: 'audit', params: {id: userId }})
				} else {
					this.search = this.emailFromRequest
				}
			}
		})
	},
	methods: {
		onInfinite () {
			this.scroll += 100
		}
	}
}
</script>
<style lang="stylus">
@import '~_settings'
.c-users

	.users-panel
		flex: 1
	.user-list
		flex: 1
		display: flex
		flex-direction: column
		position: relative
	.user
		display: flex
		flex-shrink: 0
		align-items: center
		height: 52px
		border-bottom: border-separator()
		margin-right: 1%
		> *
			padding: 0 16px
			&:first-child
				padding-left: 36px
		.last
			flex: 0
			margin-left: auto
			min-width: 100px
			color: $clr-disabled-text-light
		.name
			min-width: 150px
		#btn-show-audit, #btn-show-user
			display: flex
			margin-right: 1%
			padding: 0
			flex-shrink: 0
			align-items: center
			height: auto
			color: $ax-primary
	.profile img
		height: 32px
		width: 32px
		border-radius: 50%
	.actions
		border-bottom: border-separator()
		box-shadow: -2px 0 7px 0 rgba(0,0,0,0.16)

		display: flex
		flex-direction: column
		justify-content: space-around

		.search
			padding: 24px 16px 8px
			margin: 0
</style>

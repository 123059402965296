import general from './general'
import churn from './churn'
import churnNoContact from './churn_nocontact'
import workbook from './workbook'
const rules = {
	general,
	churn,
	churnNoContact,
	workbook
}

export default rules

<template lang="jade">
#v-app
	app-bar
	router-view(v-if="ready")
	bunt-progress-circular(v-else, size="huge", :page="true")
	snack-bar
</template>
<script>
import AppBar from 'components/app-bar'
import SnackBar from 'components/snack-bar'
import {mapGetters} from 'vuex'

import 'styles/style.styl'

export default {
	components: { AppBar, SnackBar },
	computed: {
		...mapGetters(['ready']),
	},
	created () {
		this.$store.dispatch('fetch-all')
	},
	methods: {
	}
}
</script>
<style lang="stylus">
#v-app
	> .failing
		display: flex
		flex-direction: column
		align-items: center
		h1
			font-size: 128px
		h2
			font-size: 52px
#v-app.close
	.settings-view
		padding-right: 0px

</style>

import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import errors from './errors'

import formTemplate from './forms/formtemplate'
import formData from './forms/formdata'

const cleanState = function () {
	return {
		user: null,
		pdfStroreId: null,
		template: formTemplate,
		templatedata: formData,
		form: null,
		audits: null,
		selectedAudit: 0,
		nextAuditId: 0
	}
}

const auditModule = {
	namespaced: true,
	state: cleanState(),
	actions,
	mutations,
	getters,
	errors
}

export default auditModule
export { cleanState }


<template lang="pug">
	.checkbox
		input.bar(:id="`chkbx-` + name +`-`+ index" type="checkbox" :value="option" :checked="isChecked" @change="onChange")
		label.checkbox(for="index") {{ label }}
</template>
<script>
export default {
	props: {
		option: {
			type: [String, Boolean],
			required: true
		},
		value: {
			type: [Array, Boolean],
			required: true
		},
		index: {
			type: Number,
			required: true
		},
		name: {
			type: String,
			required: true
		},
		label: {
			type: String,
			required: true
		}
	},
	computed: {
		isChecked: function () {
			return typeof this.option === 'boolean' ? this.option : this.value.indexOf(this.option) > -1
		}
	},
	methods: {
		onChange (event) {
			if (typeof this.option === 'boolean') {
				this.$emit('input', event.target.checked)
			} else {
				let values = [...this.value]
				if (event.target.checked) {
					values.push(event.target.value)
				} else {
					values = values.filter(
						item => item !== event.target.value)
				}
				this.$emit('input', values)
			}
		}
	}
}

</script>

<style lang="stylus">
@import '~_settings';
</style>

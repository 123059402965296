import api from 'lib/api'
import auth from 'lib/api/auth'

const actions = {
	// ===========================================================================
	// auth
	// ===========================================================================
	login ({commit, dispatch, state, getters}, {token, profile, impersonation}) {
		api.init(token)
		commit('AUTHENTICATE_USER', {token, profile, impersonation})
		commit('COMPUTE_SESSION_EXPIRY')
		api.idm.users.getMe().then((user) => {
			commit('UPDATE_USER', user)
			api.idm.tokenExchange(user.refresh_token).then((tokenExchange) => {
				commit('UPDATE_API_TOKEN', tokenExchange)
			})
		})
	},
	logout ({state}, redirectUrl) {
		auth.logout(redirectUrl)
	},
	'fetch-all' ({commit}) {
		api.idm.users.list().then((response) => {
			commit('SET_USERS', response)
		}).then(() => {
			api.quest.quests.list().then((response) => {
				commit('SET_QUESTS', response.results)
			})
		})
	}
}

export default actions

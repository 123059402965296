<template lang="jade">
.c-user.center-panel
	.user-content(v-if="user")
		.user-header
			.profile(v-if="user.picture")
				img(:src="user.picture")
			.user-contact
				.user-name(v-if="user.name") {{ user.name }}
				.user-name(v-else) {{ user.email }}
				.user-email
					a(:href="`mailto:${user.email}`") {{ user.email }}
				.info(v-if="user.date_joined")
					| #[span Joined ]
					strong {{ user.date_joined | fromnow }}
					|  #[span · Last login ]
					strong {{ user.last_login | fromnow }}
				.button-row
					.impersonate-buttons
						bunt-button#impersonate-cockpit(@click.native="impersonate('cockpit')", :loading="loadingImpersonate === 'login'") cockpit
						bunt-button#impersonate-translate(@click.native="impersonate('translate')", :loading="loadingImpersonate === 'login'") translate
						#impersonate-dev
							bunt-button(@click.native="impersonateToken", :loading="loadingImpersonate === 'token'") token
							.flyout(v-if="impersonateUrl")
								input(type='text', readonly="true", :value="impersonateUrl", @click="$event.target.select()")
					bunt-button#intercom(@click.native="intercom", :loading="loadingIntercom") open intercom
					bunt-button#logrocket(@click.native="logrocket") open Logrocket
		.user-body
			.quest-info
				.quest-task(v-for="task in onboardingTasks", @click="toggleTask(task)", :key="`${task.id}${task.state}`")
					.task-bubble(:class="task.state", :title="task.name")
					.task-name {{ task.name }}
			.user-info
				.info
					strong Groups
					ul
						li(v-for="group in user.groups")
							span.group-role(v-if="group.owners && group.owners.includes(user.email)") {{ group.name }} (owner)
							span.group-role(v-else-if="group.admins && group.admins.includes(user.email)") {{ group.name }} (admin)
							span.group-role(v-else) {{ group.name }} (member)

	bunt-progress-circular(v-else, size="huge", :page="true")
</template>
<script>
import { mapState } from 'vuex'
import api from 'lib/api'
import config from 'config'
export default {
	data () {
		return {
			user: null,
			loadingImpersonate: null,
			loadingIntercom: null,
			loadingQuests: true,
			impersonateUrl: null,
			onboardingTasks: []
		}
	},
	computed: {
		...mapState(['quests']),
	},
	created () {
		// TODO: check if user list gets updated in minverva - arrays items are not responsive: you need to change the array ref (create a new one), to keep it responsive
		api.idm.users.get(this.$route.params.id).then((response) => {
			this.user = response
		}).then(() => {
			api.quest.users.get(this.user.email).then((response) => {
				this.loadingQuests = false
				this.user.finished_tasks = api.responseToObject(response.finished_tasks)
				this.user.finished_quests = api.responseToObject(response.finished_quests)
				this.onboardingTasks = this.quests ? this.quests['onboarding-xs'].tasks : []
				const finishedSlugs = response.finished_tasks.map(task => task.task.slug) || []
				this.onboardingTasks.forEach(task => {
					this.$set(task, 'state', finishedSlugs.includes(task.slug) ? 'done' : 'waiting')
				})
			})
		})
	},
	methods: {
		impersonate (target) {
			this.loadingImpersonate = target
			api.idm.users.impersonate(this.user.id).then((response) => {
				let url = config[target === 'translate' ? 'translate' : 'cockpit'].baseUrl
				url += '#access_token=impersonation&token_type=Bearer&id_token=' + response.token
				if (target === 'token') {
					this.impersonateUrl = url
					navigator.clipboard.writeText(url)
				} else {
					window.open(url)
				}
				this.loadingImpersonate = false
			})
		},
		impersonateToken () { return this.impersonate('token') },
		intercom () {
			this.loadingIntercom = true
			api.idm.users.intercom(this.user.id).then((response) => {
				window.open(response.url, '_blank')
				this.loadingIntercom = false
			})
		},
		logrocket () {
			const url = 'https://app.logrocket.com/susxe1/nagini/sessions?filters='
			const filter = [
				{
					type: 'email',
					operator: {
						name: 'is',
						type: 'IS',
						hasStrings: true,
						autocompleteEnabled: true
					},
					strings: [this.user.email]
				}
			]
			// logrocket filter needs to be encoded twice
			const encodedURL = url + encodeURIComponent(encodeURIComponent(JSON.stringify(filter)))
			window.open(encodedURL, '_blank')
		},
		toggleTask (task) {
			const taskState = task.state
			task.state = 'pending'
			if (taskState === 'waiting') {
				api.quest.tasks.finish(task.id, this.user.email).then((response) => {
					task.state = 'done'
				})
			}
		}
	}
}
</script>
<style lang="stylus">
@import '~_settings'
.c-user
	display: flex
	flex-direction: column
	background-color: $clr-white
	overflow-y: scroll

	.user-content
		position: relative
		flex: 1
		align-self: center
		width: 90%

		.user-header
			display: flex
			flex-direction: row
			align-items: center
			width: 100%
			border-bottom: border-separator()
			margin-bottom: 32px

			.profile img
				height: 128px
				width: 128px
				padding: 32px

			.user-name
				font-size: 18px

			.button-row
				button
					margin: 8px 8px 8px 0

				.impersonate-buttons
					button::before
						content: ""
						height: 20px
						width: 36px
						margin-right: 8px
						background: url("../assets/images/dealwithit.png") center
						background-size: contain
						background-repeat: no-repeat
					#impersonate-dev
						display: inline-block
						position: relative
					.flyout
						z-depth-4dp()
						z-index: 1
						position: absolute
						top: 40px
						left: 0px
						padding: 8px
						background-color: $clr-white
						border: border-separator()

		.user-body
			display: flex
			flex-direction: row

			.quest-info
				display: flex
				flex-direction: column
				flex: 1
				.quest-task
					cursor: pointer
					display: flex
					align-items: center
					.task-bubble
						border: 3px solid
						border-radius: 100%
						margin: 8px
						height: 24px
						width: 24px
						&.waiting
							border-color: $clr-grey-300
						&.done
							border-color: $clr-primary
					&:hover .task-bubble
						border-width: 4px
						margin: 7px

			.user-info
				flex: 1
				line-height: 24px
				border-left: border-separator()
				padding-left: 16px
				ul
					line-height: 1
</style>

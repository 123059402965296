<template lang="pug">
	.audit-summary
		.form-group
			.form-section(v-if="form")
				.color-summary
					each category in ['usecase', 'data','team','atml','roadmap', 'adoption']
						.box
							p= category.toUpperCase()
							each light in ['flags','gates','success']
								svg.lights
									rect(:fill='form.' + category + '.colors.' + light)
		.form-group
			.form-section
				p Update Licence Category
				bunt-select(name="recommendation" label="Select Recommendation" v-model="form.recommendation" :options="recommendationOptions" @input="$emit('qa:task', 'generate')")
			.form-section
				p Generated Recommendation
				p(v-if="form.recommendation_text") {{ form.recommendation_text }}
				p(v-else-if="form.recommendation")
					bunt-progress-circular( size="small")
				p(v-else) Not selected
		.form-group(v-if="isChurnOrCancel")
			p Enter churn reason
			bunt-select(name="churnReason" label="Select the churn reason" v-model="form.churn_reason" :options="templatedata.churn_reasons")
		.form-group(v-if="isWorkbook")
			p Enter Workbook Link
			bunt-input#input-normal(name="workbookLink" label="Enter Workbook Link" v-model="form.workbook")
		.form-group
			.form-section
				p Edit Summary:
				textarea(v-model="form.summary" placeholder="brief call summary with key points" cols="80" rows="5")
		.form-group
			p PDF
			.form-section.pdf
				bunt-button#formpreview.buttonpr(@click="openPDF()") Preview
				bunt-button#formdownload.buttonpr(@click="downloadPDF()") Download
		.form-group
			p Submit Audit
			.form-section(v-if="v.$invalid")
				label.form__label Fill out all of the mandatory fields:
				ul.list__ul
					li(v-if="v.projectname && v.projectname.$invalid") Projectname
					li(v-if="isWorkbook && v.workbook.$invalid") Enter a valid link for the workbook.
					li(v-if="v.audit_cause && v.audit_cause.$invalid") Audit Cause
					li(v-if="v.branch && v.branch.$invalid") General -> Usecase (Branche)
					li(v-if="v.branchtype && v.branchtype.$invalid") Usecase -> Gates
					li(v-if="v.branchcase && v.branchcase.$invalid") General -> Category
					li(v-if="v.agent && v.agent.$invalid") CSA
					li(v-if="v.sr && v.sr.$invalid") SR
					li(v-if="v.summary && v.summary.$invalid") Summary
					li(v-if="v.recommendation && v.recommendation.$invalid") Recommendation
					li(v-if="isChurnOrCancel && v.churn_reason.$invalid") Churn Reason
			.form-section.submit(v-else)
				bunt-button#formsubmit.buttonpr(@click="uploadForm('submit')") Submit
				bunt-button#formssave.buttonpr(@click="uploadForm('save')") Save
</template>
<script>
import { mapGetters } from 'vuex'
import { openPDF, downloadPDF, storePDF } from 'lib/api/audit-helper/pdfhelpers'

export default {
	props: {
		v: {
			type: Object,
			required: true
		},
	},
	data () {
		return {
		}
	},
	computed: {
		...mapGetters({
			form: 'audit/get_auditForm',
			templatedata: 'audit/get_templatedata',
		}),
		recommendationOptions () {
			return this.getrecommendationOptions(this.form.audit_cause)
		},
		isChurnOrCancel () {
			return (this.form.audit_cause === 'Churn') || (this.form.recommendation === 'cancel')
		},
		isWorkbook () {
			return this.form.recommendation === 'start_workbook'
		},
	},
	methods: {
		openPDF: openPDF,
		downloadPDF: downloadPDF,
		storePDF: storePDF,
		getrecommendationOptions (auditCause, options) {
			if (auditCause === 'Kickoff Call') {
				return ['confirm_cancel', 'continue_with_license', 'start_workbook']
			} else if (auditCause === 'Revision Call') {
				return [
					'force_cancel',
					'force_digitalization_fastlane',
					'offer_3_month_adoption_project',
					'offer_service_creation_package',
					'force_noncommercial_license',
					'force_expansion_process',
					'continue_with_license',
					'start_workbook',
				]
			} else if (auditCause === 'Post Onboarding') {
				return [
					'offer_3_month_adoption_project',
					'start_workbook',
					'offer_service_creation_package',
					'force_noncommercial_license',
					'force_expansion_process',
					'continue_with_license'
				]
			} else if (auditCause === 'Churn') {
				return this.templatedata.recommendation_churn_options
			} else {
				return this.templatedata.recommendation_normal_options
			}
		},

		uploadForm (arg) {
			this.$store.dispatch('audit/set_uploadtime')
			this.$store.dispatch('audit/set_submitStatus', arg)
			this.v.$touch()
			if (!this.v.$invalid) {
				if (arg === 'save') {
					this.$store.dispatch('audit/save_audit').then((response) => {
						if (response.id) {
							this.$store.dispatch('audit/fetch_audits', this.$store.getters['audit/get_useremail'])
						} else {
							console.log('Form could not be saved; server did not response correctly on save.')
							window.alert('Could not upload the form, try again in a few minutes.')
						}
					})
				} else if (arg === 'submit') {
					const confirmed = confirm('Click OK to continue?')
					if (confirmed) {
						this.$store.dispatch('audit/save_audit').then(
							(response) => {
								if (response.id) {
									this.$store.dispatch('audit/store_pdf', response.id).then(apiresponse => this.$store.dispatch('audit/send_recommendations', apiresponse)).then(
										(success) => {
											if (success) {
												this.$store.dispatch('audit/fetch_audits', this.$store.getters['audit/get_useremail'])
											} else {
												console.log('Form could not be saved; fetched audits failed.')
												window.alert('Could not upload the form, try again in a few minutes.')
											}
										}
									)
								} else {
									console.log('Form could not be saved; server did not response correctly.')
									window.alert('Could not upload the form, try again in a few minutes.')
								}
							}
						)
					}
				}
			}
		}
	}
}
</script>

<style lang="stylus">
@import '~_settings';

.form-group
	flex: 1
	display: flex
	flex-direction: column
	padding: 16px
	position: relative
	flex: none
	margin: 0 0
	padding: 0 0
	border-bottom: border-separator()
	p:first-of-type
		font-weight: bold
	.form-section
		padding: 0 0 10px 0
		> bunt-button
			button-style(color: $clr-primary)

		p:first-of-type
			font-weight: bold

.color-summary
	display: flex
	flex-wrap: wrap
	justify-content: space-between
	.box
		flex: 0 32%;
		height: auto;
		p
			margin: 0 0

.lights
	width: 20px
	height: 20px
	rect
		width: 100%
		height: 100%
		stroke-width: 3
		stroke rgb(0, 0, 0)
</style>

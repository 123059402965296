/* global ENV_DEVELOPMENT:false */
import Vue from 'vue'
import Router from 'vue-router'
import Buntpapier from 'buntpapier'
import Vuelidate from 'vuelidate'
import moment from 'moment'

import auth from 'lib/api/auth'
import store from 'store'
import router from 'router'

import 'components/filters'
import 'components/directives'
import Main from './main.vue'

import 'roboto-fontface'
import 'roboto-fontface/css/roboto-condensed/roboto-condensed-fontface.css'
import 'typeface-roboto-mono'
import '@mdi/font/css/materialdesignicons.css'

import woopra from 'lib/api/woopra'

Vue.use(Router)
Vue.use(Buntpapier)
Vue.use(Vuelidate)
Vue.moment = moment

const init = () => {
	router.$init()
	router.beforeEach((to, from, next) => {
		if (to.matched.some(record => record.meta.requiresAuth) && !store.state.user.authenticated) {
			// store the url for later redirect. this has to survive two hard navigations from auth0.
			localStorage.setItem('redirectPath', `${window.location.pathname}${window.location.search}`)
			next('/login')
		} else {
			next()
		}
	})

	Main.store = store
	Main.router = router
	window.vapp = new Vue(Main).$mount('#v-app')
	// restore url before login
	if (store.state.user.authenticated && localStorage.redirectPath) {
		router.replace(localStorage.redirectPath)
		localStorage.removeItem('redirectPath')
	}
}

auth.on('authenticated', () => {
	store.dispatch('login', {
		token: auth.token,
		profile: auth.profile,
	})
	if (router.initialized) {
		router.replace('/')
	}
})

auth.init().then(init)

// 3rd party
woopra.init()

if (ENV_DEVELOPMENT) {
	window.vuex = store
}

<!--
Global error message popup renderer, right bottom corner.
!-->
<template lang="jade">
.snackbar-container
	transition-group(name="snackbar")
		div(v-for="message in messages", :key="message.timestamp")
			.snackbar.message
				.message {{ message.message }}
				bunt-icon-button(v-if="message.undo", @click.native="Undo(message)") reply
		div(v-for="error in errors", :key="error.timestamp")
			.snackbar.error(:title="error.message")
				.error-message {{ error.message }}
				bunt-icon-button(@click.native="dismissError(error)") close
		.snackbar.error.session-warning(v-if="user.sessionExpiresIn < 30", key="session-warning")
			.error-message(v-if="user.sessionExpiresIn <= 0") Your session has expired, please refresh the page now!
			.error-message(v-else) Your session expires in {{ user.sessionExpiresIn }} mins, please refresh the page
			bunt-icon-button(@click.native="location.reload()") refresh
</template>
<script>
import {mapState} from 'vuex'
import store from 'store'

export default {
	name: 'snackbar',
	components: {},
	data () {
		return {
			location: window.location
		}
	},
	computed: {
		...mapState(['user']),
		...mapState('messaging', ['messages', 'errors'])
	},
	mounted () {
	},
	attached () {},
	methods: {
		dismissMessage (message) {
		},
		dismissError (error) {
			store.commit('messaging/DISMISS_ERROR', error)
		}
	}
}
</script>
<style lang="stylus">
@import '~_settings'

.snackbar-container
	z-index: 99999;
	position: fixed
	bottom: 16px
	right: 32px
	min-width: 288px
	max-width: 668px

.snackbar
	card()
	background-color: $clr-blue-grey-900
	color: $clr-primary-text-dark
	font-size: 14px
	height: 48px
	line-height: 48px
	padding: 0px 0px 0px 24px
	margin-bottom: 10px
	position: relative
	top: 0px
	&.error
		display: flex
		justify-content: space-between
		align-items: center
		background-color: $clr-danger
	&.message
		display: flex
		justify-content: space-between
		align-items: center
		.bunt-icon-button
			icon-button-style(color:$clr-primary-text-dark, style:'clear')
	.error-message,.message
		width: 288px
		white-space: nowrap
		overflow: hidden
		text-overflow: ellipsis
		padding-right: 24px

	&.session-warning .error-message
		width: auto

	.bunt-icon-button
		margin-right: 4px
		icon-button-style(color:$clr-primary-text-dark, style:'clear')
.snackbar-enter-active, .snackbar-leave-active
	transition: all .3s ease

.snackbar-enter, .snackbar-leave
	transform: translate(0, 150px)
.snackbar-leave-active
	transform: translateX(120%)
</style>

const mutations = {
	SET_AUDITS (state, audits) {
		state.audits = audits
	},
	SET_USER (state, user) {
		state.user = user
	},
	SET_PDFSTORE_ID (state, id) {
		state.pdfStroreId = id
	},
	SET_AUDITFORM (state, audit) {
		state.form = audit
	},
	SET_SELECTEDAUDIT (state, num) {
		state.selectedAudit = num
	},
	SET_NEXTAUDITID (state, num) {
		state.nextAuditId = num
	}
}

export default mutations
